@keyframes mainBackgroundGradientFlow {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 160%;
  }
  100% {
    background-size: 100%;
  }
}

.pages-stack--open .page {
  &__overlay {
    opacity: 0.8;
  }
}

.page {
  &.-radial {
    background-image: radial-gradient(
      circle at 50% -100%,
      rgba(68, 101, 165, 0.8),
      rgba(15, 12, 29, 0.8) 63%
    );
    animation: mainBackgroundGradientFlow 15s ease infinite;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10rem;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);

    &.-bottom {
      padding-bottom: 10rem;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 500;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #2a2b30;
    opacity: 0;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.26, 0.66, 0.45, 0.78);
  }

  &__header {
    display: block;
    position: relative;
    z-index: 100;
    padding: 0 0 0.6em 0.1em;
    color: $c-accent;
    font-size: 0.85em;
    font-weight: 700;
    letter-spacing: 0.5em;
    text-transform: uppercase;
  }

  &__title {
    margin: 0;
    color: #fff;
    font-family: $f-teritary;
    font-size: 3rem;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
    word-spacing: 100vw;
  }

  &__subtitle {
    font-family: $f-teritary;
    font-size: 2rem;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.26, 0.66, 0.45, 0.78);
  }

  &__desc {
    margin: 0.5em 0 1em;
    padding: 0;
    font-size: 0.95em;
  }
}

@media (min-width: 576px) {
  .page__wrapper {
    max-width: 540px;
  }

  .page {
    &__title {
      font-size: 5rem;
    }
    &__subtitle {
      font-size: 3rem;
    }
  }
}
@media (min-width: 768px) {
  .page__wrapper {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .page__wrapper {
    max-width: 960px;
  }

  .page {
    &__title {
      font-size: 10rem;
    }
  }
}
@media (min-width: 1400px) {
  .page__wrapper {
    max-width: 1180px;
  }
}

.banner {
  display: flex;
  width: 100%;
  padding: 0 1rem;
  background-color: #fff;
  color: #000;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  &.--gray {
    background-color: rgb(249, 249, 249);
  }

  &__title {
    h2,
    h3 {
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.5em;
      font-weight: 700;
      line-height: 1.3;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  &__brand {
    aspect-ratio: attr(width) / attr(height);
    object-fit: contain;
  }

  p {
    margin: 0;
    padding: 0;
    color: #000;
    text-align: justify;
  }
}

.polygon {
  position: relative;
  height: 100%;
  margin: 0px;
  overflow: hidden;

  &__presenter {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
