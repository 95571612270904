.-hidden {
  display: none;
}

.-blanked {
  opacity: 0;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.26, .66, .45, .78);
}

.-w-100 {
  width: 100%;
}

.-hmv-100 {
  min-height: 100vh;
}

.-hmv-50 {
  min-height: 50vh;
}

.-flex {
  display: flex;
}

.-flex-center {
  display: flex;
  align-items: center;
}

.-flex-justify-center {
  justify-content: center;
}

.-flex-beetween {
  justify-content: space-between;
}

.-font-primary {
  font-family: $f-primary;
}

.-font-secondary {
  font-family: $f-secondary;
}

.-color-white {
  color: $c-white;
}

.-color-light {
  color: $c-light;
}

.-color-dark {
  color: $c-dark;
}

.-color-secondary {
  color: $c-secondary;
}

.-text-center {
  text-align: center;
}

.-text-justify {
  text-align: justify;
  text-justify: inter-word;
}

@each $weight in $font-weights {
  .-fw-#{$weight} {
    font-weight: $weight;
  }
}

@for $i from 1 through 5 {
  h#{$i} {
    font-size: #{7 - $i}rem;
  }
}

@for $i from 1 through 10 {
  .-mh-#{$i * 10} {
    min-height: #{$i * 10}vh;
  }
}

@for $i from 1 through 9 {
  .-rounded-0#{$i} {
    border-radius: #{$i * 0.1}rem;
  }
}

@for $i from 1 through 10 {
  .-rounded-#{$i} {
    border-radius: #{$i}rem;
  }
}

@for $i from 1 through 10 {
  .-mr-#{$i} {
    margin-right: #{$i}rem;
  }
  .-ml-#{$i} {
    margin-left: #{$i}rem;
  }
  .-mt-#{$i} {
    margin-top: #{$i}rem;
  }
  .-mb-#{$i} {
    margin-bottom: #{$i}rem;
  }

  .-lg-mr-#{$i} {
    @include media-breakpoint-up(md) {
      margin-right: #{$i}rem;
    }
  }

  .-lg-ml-#{$i} {
    @include media-breakpoint-up(md) {
      margin-left: #{$i}rem;
    }
  }

  .-pt-#{$i} {
    padding-top: #{$i}rem;
  }
  .-pb-#{$i} {
    padding-bottom: #{$i}rem;
  }
  .-pl-#{$i} {
    padding-left: #{$i}rem;
  }
  .-pr-#{$i} {
    padding-right: #{$i}rem;
  }
}
