$f-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$f-primary: 'Arimo', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$f-secondary: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$f-teritary: 'Raleway', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$c-accent: #27aa9b;
$c-background: #1d1e21;
$c-foreground: #cecece;
$c-foreground-secondary: #fff;

$fs-html: 10px;
$fs-body: 1.7rem;
$fs-small: 1.2rem;

/* Font Weights */
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-bold: 700;
$fw-black: 800;
$fw-extra-black: 900;

/* Colors */
$c-white: #fff;
$c-light: rgb(243, 244, 245);
$c-dark: rgb(25, 28, 31);
$c-secondary: rgb(82, 92, 102);
$c-component-background: rgb(243, 244, 245);
$c-hyperlink: rgb(0, 117, 235);
$c-hyperlink-faded: rgb(139, 149, 158);

$font-weights: $fw-thin, $fw-light, $fw-regular, $fw-bold, $fw-black, $fw-extra-black;