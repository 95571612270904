@keyframes typedjsBlink{
  50% { opacity: 0.0; }
}

@-webkit-keyframes typedjsBlink{
  0% { opacity: 1; }
  50% { opacity: 0.0; }
  100% { opacity: 1; }
}

.typed-cursor{
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}

.typed-fade-out{
  opacity: 0;
  transition: opacity .25s;
  -webkit-animation: 0;
  animation: 0;
}