.courses-box {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    li {
        margin-top: 0;
    }

    li::before {
        content: "";
        background-image: url("../../../public/img/check.png");
        background-position: center;
        background-size: cover;
        opacity: 0.5;

        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right:4px;
    }
}