/* Resets */
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

html {
  height: 100%;
  line-height: 1.15;
  box-sizing: border-box;
}

body {
  display: block;
  height: 100%;
  margin: 0;
  margin: 0;
  padding: 0;
  background-color: $c-background;
  color: $c-foreground;
  font-family: "Arimo", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625em;
  line-height: 1.5;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    display: flex;
    height: 100%;
    background-image: radial-gradient(circle at 50% -100%, rgba(68, 101, 165, 0.8), rgba(15, 12, 29, 0.8) 63%);
    flex-direction: column;

    & > .container {
      flex: 1 0 auto;
    }

    & > .expanded-footer {
      flex-shrink: 0;
    }
  }
}

a {
  text-decoration: none;

  &.faded {
    color: $c-hyperlink-faded;

    &:hover {
      color: black;
    }
  }
}

small,
.small {
  line-height: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #8b959e;
}

h1,
h2,
h3,
h4,
h5 {
  &.accent {
    font-family: $f-primary;
    font-weight: 700;
  }
}

form,
.form {
  width: 100%;
}

.btn {
  display: inline-flex;
  height: 4.5rem;
  padding: 0 2rem;
  border-radius: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  align-items: center;
  justify-content: center;

  &-mobile {
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.btn-dark {
  &:hover {
    background: transparent;
    color: $c-dark;
  }
}

.btn-secondary {
  &:hover {
    background: $c-dark;
    color: $c-light;
  }
}

.modal {
  .modal-title {
    font-family: $f-secondary;
    font-weight: 700;
  }
}

button.a {
  margin: 0;
  padding: 0;
  background: transparent;
  color: $c-accent;
  border: none;
  box-shadow: none;
  font-size: 1rem;

  &:hover {
    color: #fff;
    cursor: pointer;
  }
}

a {
  color: $c-accent;
  outline: none;
  text-decoration: none;

  &:hover {
    color: #fff;
  }

  &.-black {
    &:hover {
      color: #000;
    }
  }
}

.blockquote {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  color: #6c757d;
  font-size: 80%;
  margin: 0px;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}
