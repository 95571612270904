/**
 * This Source Code Form is subject to the terms of the GNU GPL-3.0 License.
 * If a copy of the GPL-3.0 was not distributed with this file, You can obtain one at https://www.gnu.org/licenses/gpl-3.0.en.html.
 * Copyright (C) 2022 Leszek Pomianowski.
 * All Rights Reserved.
 */

@charset "UTF-8";

//@import "~bootstrap/scss/bootstrap";

@import "fonts";
@import "variables";

@import "~bootstrap/scss/bootstrap";
// @import './bootstrap/functions';
// @import './bootstrap/variables';
// @import './bootstrap/breakpoints';
// @import './bootstrap/bootstrap';

@import './common/modifiers';
@import './common/base';
@import './common/loading';
@import './common/page';
@import './common/blueprint';
@import './common/scrollbar';
@import './common/typed';

@import './components/pricing';
@import './components/courses';