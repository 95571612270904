/*!
 * Page Stack Navigation - Modified by lepo.co
 * Copyright © 2021-2022 lepo.co 
 * Partialy based on Blueprint: Page Stack Navigation
 * Copyright © Codrops 2015 <http://www.codrops.com>
 * 
 * Integrate or build upon it for free in your personal or commercial projects.
 * Don't republish, redistribute or sell "as-is".
 */

@keyframes preBackgroundGradientFlow {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 160%;
  }
  100% {
    background-size: 100%;
  }
}

.no-js {
  .menu-button {
    display: none;
  }
}

.js {
  .pages-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    padding: 30px;
    background: transparent;
    opacity: 0;
    -webkit-transform: translate3d(0, 150px, 0);
    transform: translate3d(0, 150px, 0);
    -webkit-transition: -webkit-transform 1.2s, opacity 1.2s;
    transition: transform 1.2s, opacity 1.2s;
    pointer-events: none;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

    &__item {
      padding: 0 10%;
    }

    &.--open {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      pointer-events: auto;
    }
  }

  .pages-stack {
    z-index: 100;
    pointer-events: none;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    -webkit-perspective-origin: 50% -50%;
    perspective-origin: 50% -50%;

    &.--open {
      .page {
        -webkit-transition: -webkit-transform 0.45s, opacity 0.45s;
        transition: transform 0.45s, opacity 0.45s;
        cursor: pointer;
        -webkit-transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
        transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
      }
    }

    .page {
      position: relative;
      z-index: 5;
      width: 100%;
      height: 100vh;
      overflow-x: hidden;
      pointer-events: auto;
      //box-shadow: 0 -1px 10px rgb(0 0 0 / 10%);
  
      &.--inactive {
        display: none;
        position: absolute;
        top: 0;
        z-index: 0;
        opacity: 0;
      }
    }
  }
}

.pages-nav {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  background: #0e0f0f;
  text-align: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;

  &__item {
    width: 33%;
    padding: 1em;

    &.--social {
      width: 100%;
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
      -webkit-transition: -webkit-transform 1.2s, opacity 1.2s;
      transition: transform 1.2s, opacity 1.2s;
      -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    }

    &.--open {
      &.--social {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition-delay: 0.35s;
        transition-delay: 0.35s;
      }
    }
  }

  .link {
    position: relative;
    font-size: 0.85em;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: #fff;
    }

    &.--social {
      margin: 0 0.75em;
      font-size: 1.5em;
    }

    &.--faded {
      color: #4f4f64;
    }

    &.--faded:hover,
    &.--faded:focus {
      color: $c-accent;
    }

    &.--faded {
      font-size: 0.65em;
    }

    &.--page:not(.--faded) {
      display: block;
      color: #cecece;

      &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 30px;
        height: 2px;
        margin: 5px 0 0 -15px;
        background: #fff;
        -webkit-transform: scale3d(0, 1, 1);
        transform: scale3d(0, 1, 1);
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        content: "";
      }

      &:hover {
        &:before {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }

  .text-hidden {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    color: transparent;
    overflow: hidden;
  }
}

.pages-stack {
  &.--open {
    overflow: hidden;
  
    .page {
      &.--inactive {
        display: block;
      }
    }
  }

  .page {
    &.-current {
      position: relative;
    }
  
    &__main {
      z-index: 1;
      //transition: opacity 0.3s, visibility 0.3s;
  
      &.-show {
        opacity: 1;
        //visibility: visible;
      }
      &.-hide {
        opacity: 0;
        //visibility: hidden;
      }
    }
  
    &__preload {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      transition: opacity 0.3s, visibility 0.3s;
      align-items: center;
      animation: preBackgroundGradientFlow 15s ease infinite;
      background-image: radial-gradient(circle at 50% -100%, rgba(68, 101, 165, 0.8), rgba(15, 12, 29, 0.8) 63%);
  
      &.-show {
        opacity: 1;
        visibility: visible;
      }
      &.-hide {
        opacity: 0;
        visibility: hidden;
      }
    }
  
    .info {
      margin-top: 1.5em;
      font-size: 1.25em;
      max-width: 50%;
    }
    
    .poster {
      position: absolute;
      bottom: 4vh;
      left: 60%;
      z-index: -1;
      max-width: 100%;
      max-height: 80%;
    }
  }
}

/* Menu button */

.menu-button {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1000;
  width: 30px;
  height: 24px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:before,
  &:after,
  span {
    background: #5f656f;
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    content: "";
    pointer-events: none;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    overflow: hidden;
    text-indent: 200%;
  }

  &:before {
    -webkit-transform: translate3d(0, -10px, 0) scale3d(0.8, 1, 1);
    transform: translate3d(0, -10px, 0) scale3d(0.8, 1, 1);
  }

  &:after {
    -webkit-transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
    transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
  }

  &.--open {
    span {
      opacity: 0;
    }

    &:before {
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg);
    }
    &:after {
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);
    }
  }
}

@media screen and (max-width: 60em) {
  .info {
    max-width: 100%;
  }
  .poster {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    margin: 0 0 0 50%;
    max-width: 100%;
    max-height: 50vh;
  }
  .pages-nav__item {
    width: 50%;
    min-height: 20px;
  }
  .link.--page {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .link.--social {
    margin: 0 0.1em;
  }
}

@media screen and (max-width: 40em) {
  .js .pages-nav {
    display: block;
    padding: 10px 20px 0 20px;
    text-align: left;
  }
  .js .pages-nav__item {
    width: 100%;
    padding: 4px 0;
  }
  .js .pages-nav__item--small {
    display: inline-block;
    width: auto;
    margin-right: 5px;
  }
  .pages-nav__item--social {
    font-size: 0.9em;
  }
  .menu-button {
    top: 15px;
    right: 30px;
    left: auto;
  }
  .info {
    font-size: 0.85em;
  }
  .poster {
    margin: 1em;
  }
}
