@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.pre-animation {
  max-width: 100%;

  &__element {
    position: relative;
    margin:  0;

    background: 0 0;
    background: linear-gradient(to right, #242528 8%, #1f2023 18%, #242528 33%);
    background-size: 800px 104px;
    border-radius: 5px;

    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;

    &.-paragraph {
      width: 730px;
      height: 18px;
      margin: 10px 0;
    }

    &.-header {
      width: 1200px;
      height: 130px;
      margin-top: 5px;
      margin-bottom: 25px;
    }
  }
}
